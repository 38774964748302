import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Tag,
  Spin,
} from "antd"
import Text from "antd/lib/typography/Text"
import { navigate } from "gatsby"
import React, { useContext, useEffect, useRef, useState } from "react"
import { openCustomNotificationWithIcon } from "../../../../common/notifycation"
import { AccountContext } from "../../../../context/account"
import {
  createAccount,
  deleteAccount,
  getOneAccount,
  updateAccount,
} from "../../../../services/account"

import { formatPhoneNumber, isValidPhoneNumber } from "react-phone-number-input"
import useWindowDimensions from "../../../../hooks/GetWidthHeightWindow/useWindowDimensions"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input/input"
import textAndRules from "../../../../utils/textAndRules"

function HandleCompanyInfo() {
  const { user } = useContext(AccountContext)
  const numberSecond = useRef()
  const numberThird = useRef()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [checkPage, setCheckPage] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const onChange = (value) => {
    // console.log(`selected ${value}`)
  }

  const [inputPhone, setInputPhone] = useState(null)

  const { width, height } = useWindowDimensions()
  const onSearch = (value) => {
    console.log("search:", value)
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    let roles = 0
    if (values.roles == "1") {
      roles = "admin"
    } else if (values.roles == "2") {
      roles = "developer"
    } else {
      roles = "member"
    }
    const data = {
      email: values.email,
      role: roles,
      first_name: values.firstName,
      last_name: values.lastName,
      metadata: {
        phone: values.phone,
        company_name: user.metadata.company_name,
      },
    }
    if (!checkPage) {
      const { response } = await createAccount(data)
      if (response.status == 200) {
        openCustomNotificationWithIcon(
          "success",
          "アカウント作成",
          "このアカウントを作成できました。"
        )
        setLoading(false)
        navigate("/company-info", { state: { page: "5" } })
      } else {
        openCustomNotificationWithIcon(
          "error",
          "アカウント作成",
          response.response.data.message
        )
        setLoading(false)
      }
    } else {
      // update
      const href = window.location.href.split("/")
      const id = href[href.length - 1]
      const dataUpdate = { ...data, api_token: "" }
      delete dataUpdate["email"]
      delete dataUpdate["phone"]
      const { response } = await updateAccount(id, dataUpdate)

      if (response.status == 200) {
        openCustomNotificationWithIcon(
          "success",
          "update account",
          "update account success"
        )
        setLoading(false)
        navigate("/company-info", { state: { page: "5" } })
      } else {
        openCustomNotificationWithIcon(
          "error",
          "update account",
          "update account error"
        )
        setLoading(false)
      }
    }
  }
  const handleOk = async () => {
    setLoading(true)
    const href = window.location.href.split("/")
    const id = href[href.length - 1]
    const { response } = await deleteAccount(id)

    if (response.status == 200) {
      openCustomNotificationWithIcon(
        "success",
        "notifycation Success",
        "Delete Success."
      )
      setIsModalVisible(false)
      setLoading(false)
      navigate("/company-info", { state: { page: "5" } })
    } else {
      openCustomNotificationWithIcon(
        "error",
        "notifycation Error",
        "Delete Error."
      )
      setIsModalVisible(false)
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  useEffect(() => {
    const href = window.location.href.split("/")
    const id = href[href.length - 1]
    if (id != "create") {
      setCheckPage(true)
      getDataDetail(id)
    }
  }, [])

  const getDataDetail = async (id) => {
    setIsLoading(true)
    const { response } = await getOneAccount(id)
    if (response.status == 200) {
      let roles = ""
      if (response.data.user.role == "admin") {
        roles = "1"
      } else if (response.data.user.role == "developer") {
        roles = "2"
      } else {
        roles = "3"
      }

      setInputPhone(formatPhoneNumber(response.data?.user?.metadata?.phone))

      form.setFieldsValue({
        firstName: response.data.user.first_name,
        lastName: response.data.user.last_name,
        phone: response.data.user?.metadata?.phone,
        email: response.data.user.email,
        roles: roles,
      })
      setIsLoading(false)
    } else {
      openCustomNotificationWithIcon(
        "error",
        "Get data account",
        "Get data account error"
      )
    }
  }

  const checkInputPhoneNumber = (_, value) => {
    if (value) {
      if (isValidPhoneNumber(value)) {
        const array = formatPhoneNumber(value).split("")
        if (array[1] == "0") {
          return Promise.reject(
            new Error(textAndRules.pleaseInputANumberPhoneAvaiable)
          )
        }
        if (array[0] !== inputPhone?.split("")[0]) {
          return Promise.reject(
            new Error(textAndRules.pleaseInputANumberPhoneAvaiable)
          )
        }
        if (array[1] !== inputPhone?.split("")[1]) {
          return Promise.reject(
            new Error(textAndRules.pleaseInputANumberPhoneAvaiable)
          )
        }
        return Promise.resolve()
      }
      return Promise.reject(
        new Error(textAndRules.pleaseInputANumberPhoneAvaiable)
      )
    }
    return Promise.reject(new Error(""))
  }
  return (
    <div className="mx-2 flex justify-center">
      <div className="p-20" style={{ width: "80%", background: "#fff" }}>
        <Spin spinning={isLoading}>
          <Form
            form={form}
            colon={false}
            name="account"
            onFinish={handleSubmit}
            onChange={(e) => {
              // console.log(e)
              if (e.target.id == "account_phone") {
                if (e.target.value) {
                  setInputPhone(e.target.value)
                }
              }
            }}
          >
            {/* dong 1 */}
            <Form.Item
              label={
                <Space style={{ width: "250px" }}>
                  <Tag color="red">必須</Tag>
                  <Text>氏名</Text>
                </Space>
              }
              style={{ marginBottom: 0 }}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: textAndRules.pleaseInput,
                  },
                  {
                    whitespace: true,
                    message: "空白を入力できません。",
                  },
                ]}
                name="firstName"
                style={{
                  marginBottom: 0,
                  marginRight: "10px",
                  display: "inline-block",
                  width: "253px",
                }}
              >
                <Input placeholder="山田" style={{ width: "253px" }} />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: textAndRules.pleaseInput,
                  },
                  {
                    whitespace: true,
                    message: "空白を入力できません。",
                  },
                ]}
                name="lastName"
                style={{
                  marginTop: width < 1456 ? 10 : 0,
                  marginBottom: 0,
                  display: "inline-block",
                  width: "253px",
                }}
              >
                <Input placeholder="浩二" style={{ width: "253px" }} />
              </Form.Item>
            </Form.Item>
            <Divider />
            {/* dong 2 */}
            <Form.Item
              label={
                <Space style={{ width: "250px" }}>
                  <Tag color="red">必須</Tag>
                  <Text>電話番号</Text>
                </Space>
              }
              style={{ marginBottom: 0 }}
            >
              <Form.Item
                name={"phone"}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: textAndRules.pleaseInput,
                  },
                  {
                    validator: checkInputPhoneNumber,
                  },
                ]}
              >
                <PhoneInput
                  country="JP"
                  style={{ width: "253px" }}
                  placeholder={"000-0000-0000"}
                  className="inputNumberPhone"
                />
              </Form.Item>
            </Form.Item>
            <Divider />
            {/* dong 3 */}
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: textAndRules.pleaseInput,
                },
                {
                  whitespace: true,
                  message: "空白を入力できません。",
                },
              ]}
              label={
                <Space style={{ width: "250px" }}>
                  <Tag color="red">必須</Tag>
                  <Text>メールアドレス</Text>
                </Space>
              }
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder="name@namename.com"
                style={{ maxWidth: "517px" }}
                disabled={checkPage}
              />
            </Form.Item>
            <Divider />
            {/* dong 4 */}
            <Form.Item
              name="roles"
              rules={[
                {
                  required: true,
                  message: "※選択してください",
                },
              ]}
              label={
                <Space style={{ width: "250px" }}>
                  <Tag color="red">必須</Tag>
                  <Text>権限</Text>
                </Space>
              }
              style={{ marginBottom: 0 }}
            >
              <Select
                showSearch
                placeholder="管理者"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                style={{ maxWidth: "200px" }}
              >
                <Select.Option value="1">管理者</Select.Option>
                <Select.Option value="2">スタッフ（発注可）</Select.Option>
                <Select.Option value="3">スタッフ（発注不可）</Select.Option>
              </Select>
            </Form.Item>
            <Divider />
            <Space className="justify-center w-full">
              {checkPage && (
                <Button
                  type="danger"
                  style={{
                    width: "200px",
                    height: "37px",
                    borderRadius: "4px",
                    marginTop: "4rem",
                  }}
                  onClick={() => setIsModalVisible(true)}
                >
                  削除
                </Button>
              )}
              <Button
                style={{
                  width: 200,
                  background: "#E4E8EB",
                  height: 37,
                  marginTop: "4rem",
                }}
                onClick={() =>
                  navigate("/company-info", { state: { page: "5" } })
                }
              >
                一覧に戻る
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  width: "329px",
                  height: "37px",
                  borderRadius: "4px",
                  marginTop: "4rem",
                }}
                loading={loading}
              >
                {checkPage ? "編集する" : "追加する"}
              </Button>
            </Space>
          </Form>
        </Spin>
      </div>
      <Modal
        title="アカウント削除"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        centered
      >
        <p>このアカウントを削除しますしょうか？</p>
      </Modal>
    </div>
  )
}

export default HandleCompanyInfo
